import React from 'react';
import Layout from '../components/Layout';

import Navbar from '../components/Navbar';

import { NotFoundWrapper, Heading2, Button } from '../components/styled';

const NotFoundPage = () => (
    <Layout>
        <Navbar limited />

        <NotFoundWrapper>
            <span>404</span>
            <Heading2 center>Page not found</Heading2>
            <p>Sorry, we can’t find the page you’re looking for.</p>
            <Button onClick={() => history.back()}>Go back</Button>
        </NotFoundWrapper>
    </Layout>
);

export default NotFoundPage;
